<template>
  <div class="MyCredits">
    <AdminStatisticsFilter :statistics="statistics" :title="$locale[`my_${$route.query.type}`]">
      <LargeButton
        :icon="`filter-list`"
        :label="$locale['filters']"
        :rightIcon="false"
        :rightDesc="`<span skeleton>${number_format(numFilters)}</span>`"
        @click="
          view({
            title: $locale['filters'],
            component: () => import('@/components/widgets/InfoFilter'),
            closeOutside: true,
            class: 'menu bottom-mbl',
            animation: 'bottomIn',
          })
        "
      />
    </AdminStatisticsFilter>

    <div :class="`statistics-block __general ${$loading ? '__loading' : '__loaded'}`">
      <div class="ReportInfo">
        <LargeButton
          class="MyCreditsCard"
          v-for="(transaction, index) in items"
          :key="index"
          :label="`<span third-bold>@${transaction.user.name}</span>`"
          :desc="`<div><span third-bold>${transaction.concept}</span> <div date>${date_locale(transaction.date)}</div></div>`"
          :icon="false"
          :rightDesc="`<strong nowrap mount>${Go.toPositive(transaction.mount)}</strong>`"
          :type="transaction.type"
          :UserAvatar="transaction.user"
          @click="
            view({
              title: $locale['user'],
              component: () => import('@/components/user/User.vue'),
              class: `menu bottom`,
              closeOutside: true,
              animation: 'bottomIn',
              user: transaction.user,
            })
          "
        />
        <LoadMore :morepage="statistics.hasMore" @more="updateFeed({ page: statistics.page + 1 })" />
        <CardMessage v-if="!$loading && !statistics.totalItems" :message="$locale['nothing_found']" />
      </div>
    </div>
  </div>
</template>

<script>
import FeedList from "@/components/feed/feed.list";
import UserFeed from "@/components/user/UserFeed";
export default {
  mixins: [UserFeed, FeedList],
  components: {
    AdminStatisticsFilter: () => import("../admin/AdminStatisticsFilter.vue"),
  },
  data: function() {
    return {
      endpoint: `${process.env.VUE_APP_API_HOST}/creator/statistics-content`,
      storeId: "my_content",
      items: [],
      statistics: { totalItems: 0, page: 1 },
      limit: 20,
      axios: {
        headers: {
          "use-form": "false",
        },
      },
    };
  },
};
</script>

<style lang="scss">
.MyCredits {
  .MyCreditsCard .LargeButtonDesc {
    opacity: 1;
  }

  .MyCreditsCard [date] {
    opacity: 0.5;
    margin: $mpadding/4 0 0 0;
  }

  .MyCreditsCard [warn] {
    background-color: var(--warn-color);
    color: #fff;
    margin: $mpadding/2 0 0 0;
    display: inline-block;
  }

  .MyCreditsCard[type="reload"] [mount] {
    color: var(--color-tips);
  }
}
</style>
