var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"MyCredits"},[_c('AdminStatisticsFilter',{attrs:{"statistics":_vm.statistics,"title":_vm.$locale[("my_" + (_vm.$route.query.type))]}},[_c('LargeButton',{attrs:{"icon":"filter-list","label":_vm.$locale['filters'],"rightIcon":false,"rightDesc":("<span skeleton>" + (_vm.number_format(_vm.numFilters)) + "</span>")},on:{"click":function($event){_vm.view({
          title: _vm.$locale['filters'],
          component: function () { return import('@/components/widgets/InfoFilter'); },
          closeOutside: true,
          class: 'menu bottom-mbl',
          animation: 'bottomIn',
        })}}})],1),_c('div',{class:("statistics-block __general " + (_vm.$loading ? '__loading' : '__loaded'))},[_c('div',{staticClass:"ReportInfo"},[_vm._l((_vm.items),function(transaction,index){return _c('LargeButton',{key:index,staticClass:"MyCreditsCard",attrs:{"label":("<span third-bold>@" + (transaction.user.name) + "</span>"),"desc":("<div><span third-bold>" + (transaction.concept) + "</span> <div date>" + (_vm.date_locale(transaction.date)) + "</div></div>"),"icon":false,"rightDesc":("<strong nowrap mount>" + (_vm.Go.toPositive(transaction.mount)) + "</strong>"),"type":transaction.type,"UserAvatar":transaction.user},on:{"click":function($event){_vm.view({
            title: _vm.$locale['user'],
            component: function () { return import('@/components/user/User.vue'); },
            class: "menu bottom",
            closeOutside: true,
            animation: 'bottomIn',
            user: transaction.user,
          })}}})}),_c('LoadMore',{attrs:{"morepage":_vm.statistics.hasMore},on:{"more":function($event){return _vm.updateFeed({ page: _vm.statistics.page + 1 })}}}),(!_vm.$loading && !_vm.statistics.totalItems)?_c('CardMessage',{attrs:{"message":_vm.$locale['nothing_found']}}):_vm._e()],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }